import React from "react";
import {Image } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";


export default function CarouselComp(props) {

    return(
        <>

            <div className="sectiuneArticol">
              <Image className="photoActivity" src={props.photo} alt={props.alt}/>
              <a href={props.goTo}>
              <h1 className="articolTxt">
                {props.titluArticol}
                </h1>
              </a>
              <p className="fw-bold fs-6 pt-2">{props.dataEvent}</p>
            </div>

        </>
    )
}
