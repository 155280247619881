import React from "react";
import Title from "../components/TitleComponent";
import { Row, Col } from "react-bootstrap";

export default function NoPage() {
  return (
    <Row className="p-0 m-0">
      <Col className="d-none d-xl-block"></Col>
      <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">
        <Title title="Pagina nu exista" />
      </Col>
      <Col className="d-none d-xl-block"></Col>
    </Row>
  );
}
