import React from "react";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Objectives from "./ObjectivesComponent";

export default function ObjectivesWImage(props) {
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="12" sm="12" md="8" lg="7" xl="7" xxl="7">
            <Objectives
              highlightTitle={props.highlightTitle}
              objectives={props.objectives}
              talign="objective-align-left"
            />
          </Col>
          <Col
            xs="12"
            sm="12"
            md="4"
            lg="5"
            xl="5"
            xxl="5"
            className="d-flex flex-wrap align-items-center"
          >
            <Image
              className="photoSala d-none d-md-block"
              src={props.photoSource}
              alt={props.alt}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
