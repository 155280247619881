import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Details from "../../components/DetailsComponent";
import Objectives from "../../components/ObjectivesComponent";
import { Helmet } from "react-helmet-async";

export default function SpeechTherapy() {
  const efecteDezvLimbaj = [
    "Stari de disconfort;",
    "Dificultati de relationare;",
    "Un risc mai mare de aparitie a esecului scolar",
  ];

  const tulburariLimbaj = [
    "Tulburarile de pronuntie sau articulatie;",
    "Tulburarile de ritm si fluenta vorbirii;",
    "Tulburarile de dezvoltare a limbajului (receptiv si/sau expresiv);",
  ];

  return (
    <>
    <Helmet>
        <title>Evaluarea si interventia logopedica</title>
        <meta
          name="description"
          content="Cele mai frecvente tulburari de limbaj sunt tulburarile de pronuntie sau articulatie, tulburarile de ritm si fluenta vorbirii, tulburarile de dezvoltare a limbajului. Articol redactat de Calinescu Diana, psiholog psihopedagogie speciala si logoped in cadrul Asociatiei PsiNapsis din Sibiu."
        />
        <link rel="canonical" href="https://www.psinapsis.ro/articles/speechtherapyarticle" />
      </Helmet>

      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">
          <Title
            title="Evaluarea si interventia logopedica la copiii prescolari si la
              scolarii mici"
          />

          <Details 
          highlightText="Orice intarziere "
          normalText="in dezvoltarea limbajului, orice particularitate in achizitionarea acestuia (pronuntie defectuoasa, un ritm sau o fluenta redusa) poate crea:" />

          <Objectives objectives={efecteDezvLimbaj} />

          <Details 
          highlightText="Tulburarile de limbaj "
          normalText="frecvent intalnite la prescolari si la scolarii mici sunt:" />

          <Objectives objectives={tulburariLimbaj} />

          <Details normalText="Focusul nostru este de a depista, evalua si identifica tulburarile de limbaj si comunicare la copiii din categoria de varsta vizata." />

          <div className="text-center">
            <Image
              className="photoArticol"
              src="/images/evaluarealogopedica.jpg"
              alt="Logopedie, Diana Calinescu, Asociatia PsiNapsis Sibiu"
            />
          </div>

          <div className="pt-4">
            <Details normalText="Text redactat de: Diana Calinescu - Psiholog psihopedagogie speciala, Logoped " />
          </div>
          <Details normalText="12 octombrie 2023" />
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
