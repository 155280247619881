import React from "react";
import Title from "../components/TitleComponent";
import Details from "../components/DetailsComponent";
import Objectives from "../components/ObjectivesComponent";
import ObjectivesWImage from "../components/ObjectivesWImageComponent";
import { Image } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

export default function AboutPage() {
  const whoWeAreTitle = "Cine suntem noi?";

  const asociationDescHigh = "Asociatia PsiNapsis";
  const asocitionDescText =
    " a luat nastere din dorinta de a crea un mediu profesional incluziv, competent, armonios, un loc si o stare de spirit dominate de ideea de apartenenta si evolutie. Ne dorim sa reprezentam locul de intalnire al unor oameni cu vocatie, dedicati, meniti si capabili sa ofere sprijin psihologic copiilor, adultilor sau familiilor care au nevoie de acest sprijin. Ne propunem sa dezvoltam proiecte cu privire la educatia psiho-emotionala a copiilor si a parintilor, campanii de identificare a tulburarilor de dezvoltare si a tulburarilor de invatare, ateliere si workshopuri de dezvoltare si optimizare personala pentru adolescenti si adulti, ateliere si demersuri specifice de psihoeducatie.";

  const objectivesList = [
    "Oferirea unei palete de servicii psihologice: evaluare si interventie in tulburarile de dezvoltare (autism), in tulburarile de invatare, in intarzierile de limbaj, in tulburarile de dispozitie, terapii suportive in situatii de criza;",
    "Conceperea unor programe de dezvoltare personala – individuala si in grup, pentru copii, adolescenti, adulti;",
    "Crearea unei echipe de specialisti - psihologi clinicieni, logopezi, psihopedagogi de recuperare, asistenti sociali cu experienta si expertiza;",
    "Promovarea conceptului de psihoeducatie prin organizarea de intalniri tematice, workshopuri, ateliere;",
    "Dezvoltarea unei baze materiale si intelectuale utile – de la obiecte educative la licente de utilizare a unor instrumente psihologice;",
  ];

  const ourServices = [
    "Evaluarea nivelului de dezvoltare;*",
    "Terapie de recuperare;",
    "Stimularea limbajului si a comunicarii/ logopedie;",
    "Consiliere psihologica copii, adolescenti, adulti;",
    "Dezvoltare personala;",
    "Grupuri tematice;",
    "Grupuri de suport;",
  ];

  const asociationDescLastText =
    "*In cadrul Asociatiei PsiNapsis, se va utiliza un instrument cu popularitate crescuta in evaluarea aptitudinilor si comportamentelor copiilor cu tulburari de dezvoltare (tulburari din spectrul autist) si ale copiilor cu tulburari din sfera limbajului si comunicarii: Pep-3. In urma aplicarii acestuia este facilitata realizarea unui profil psihoeducational relevant prin evaluarea capacitatilor cognitive, verbale, sociale, motrice si comportamentale. Evaluarea initiala a copilului care se confrunta cu o tulburare de dezvoltare se concretizeaza prin programul personalizat de interventie, care cuprinde tipul de terapie recomandata, obiectivele propriu-zise si o recomandare in ce priveste frecventa sedintelor de recuperare.";

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.psinapsis.ro/" />
      </Helmet>
      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">
          <Title title={whoWeAreTitle} />

          <Details
            highlightText={asociationDescHigh}
            normalText={asocitionDescText}
          />

          <Objectives
            highlightTitle="Obiectivele "
            normalTitle="noastre sunt:"
            talign="objective-align-justify"
            objectives={objectivesList}
          />
          <Image
            className="photoSalaSm d-block d-md-none mx-auto "
            src="/images/photo001R.jpg"
            alt="Sala terapie adulti, Asociatia PsiNapsis"
          />

          <ObjectivesWImage
            photoSource="/images/photo001R.jpg"
            alt="Sala terapie adulti, Asociatia PsiNapsis"
            highlightTitle="Ce oferim?"
            objectives={ourServices}
          />

          <Details normalText={asociationDescLastText} />
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
