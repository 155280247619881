import React from "react";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function EventComponent(props) {
  return (
    <>
      <Row>
        <Col
          className="textArticol"
          xs="12"
          sm="12"
          md="12"
          lg="8"
          xl="8"
          xxl="8"
        >
          <p className="fs-6 ms-5 me-5">{props.normalText}</p>
          <div>
            <span className="ms-5 me-2">
              <Image src={props.iconD} width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">{props.date}</span>
          </div>

          <div>
            <span className="ms-5 me-2">
              <Image src={props.iconH} width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">{props.hour}</span>
          </div>

          <div>
            <span className="ms-5 me-2 mt-2">
              <Image src={props.iconL} width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">{props.location}</span>
          </div>
        </Col>
        <Col xs="12" sm="12" md="12" lg="4" xl="4" xxl="4">
          <div className="text-center">
            <Image className="photoEvent" src={props.photo} alt={props.alt} />
          </div>
        </Col>
      </Row>
    </>
  );
}
