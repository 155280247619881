import React from "react";
import Title from "../components/TitleComponent";
import { Row, Col} from "react-bootstrap";
import Details from "../components/DetailsComponent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet-async";
import CarouselComp from "../components/CarouselComponent";

export default function ActivityPage() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  const intro = "Ne propunem sa dezvoltam proiecte cu privire la educatia psiho-emotionala a copiilor si a parintilor, campanii de identificare a tulburarilor de dezvoltare si a tulburarilor de invatare, ateliere si workshopuri de dezvoltare si optimizare personala pentru adolescenti si adulti, ateliere si demersuri specifice de psihoeducatie."

  return (
    <>
      <Helmet>
        <title>Activitate</title>
        <meta
          name="description"
          content="Ne propunem sa dezvoltam proiecte cu privire la educatia psiho-emotionala a copiilor si a parintilor, campanii de identificare a tulburarilor de dezvoltare si de invatare, ateliere de dezvoltare personala pentru adolescenti si adulti, ateliere si demersuri specifice de psihoeducatie, terapii de recuperare, consiliere psihologica copii, adolescenti, adulti, logopedie."
        />
        <link rel="canonical" href="https://www.psinapsis.ro/activity" />
      </Helmet>

      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 min-xl-screen-size">
          <Title title="" />
          <Details normalText={intro} />
          <div className="pb-4"></div>
          
          <Carousel
            fade
            swipeable={true}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            keyBoardControl={true}
            customTransition="all .1"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carouselItem"
            responsive={responsive}
          >
            <CarouselComp 
              photo="/images/brosura2.png"
              alt="Lansare brosura autism, ghid pentru parinti realizat de Calinescu Diana"
              goTo="/events/autismdayevent"
              titluArticol="Ziua Internationala de Constientizare a Autismului - Lansarea brosurii pentru parinti"
              dataEvent="2 aprilie 2024"
            />
            <CarouselComp
              photo="/images/sala_terapieR.jpg"
              alt="Asociatia PsiNapsis organizeaza un grup cu participare gratuita pentru copiii cu TSA"
              goTo="/events/tsaevent"
              titluArticol="Grup cu participare gratuita pentru copiii cu TSA"
              dataEvent="februarie - mai 2024"
            />

            <CarouselComp
              photo="/images/dislexieR.jpg"
              alt="Evaluarea dislexiei si a disortografiei de dezvoltare in cadrul Asociatiei PsiNapsis"
              goTo="/events/evdislexieevent"
              titluArticol="Evaluarea dislexiei si a disortografiei de dezvoltare"
              dataEvent="1 - 2 iunie 2023"
            />
          </Carousel>

          <Title title="Articole" />
          <Carousel
            fade
            swipeable={true}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carouselItem"
            responsive={responsive}
          >
            <CarouselComp
              photo="/images/evaluarealogopedica.jpg"
              alt="Logopedie, Diana Calinescu, Asociatia PsiNapsis"
              goTo="/articles/speechtherapyarticle"
              titluArticol="Evaluarea si interventia logopedica la copiii prescolari si la
              scolarii mici"
            />

            <CarouselComp
              photo="/images/anxietate.jpg"
              alt="Terapie si consiliera adulti, Ilies Nicoleta, Asociatia PsiNapsis"
              goTo="/articles/anxietyarticle"
              titluArticol="Anxietatea"
            />

            <CarouselComp
              photo="/images/dislalieSM.jpg"
              alt="Interventia terapeutica in tulburarile de limbaj. Calinescu Diana, Asociatia PsiNapsis"
              goTo="/articles/dyslaliaarticle"
              titluArticol="Dislalia"
            />

            <CarouselComp
              photo="/images/detcog4.jpg"
              alt="Deteriorarea cognitiva, Calinescu Diana, Asociatia PsiNapsis"
              goTo="/articles/cognitiveimpairementarticle"
              titluArticol="Deteriorarea cognitiva"
            />



          </Carousel>
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
