import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from 'react-bootstrap/Container';

export default function Menu() {
    return (
      <>
        <Navbar
          bg="dark"
          data-bs-theme="dark"
          collapseOnSelect
          expand="lg"
          className="sticky-top"
        >
          <Container fluid>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
            >
              <Nav variant="underline" defaultActiveKey="#aboutus" className="ms-auto">
                <Nav.Link href="/">Despre noi</Nav.Link>
                <Nav.Link href="/team">Echipa</Nav.Link>
                <Nav.Link href="/activity">Activitate</Nav.Link>
                <Nav.Link href="/visitus">Viziteaza-ne</Nav.Link>
                <Nav.Link href="/donation">Doneaza</Nav.Link>
                <Nav.Link href="#contact">Contact</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
  