import { Outlet } from "react-router-dom";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "../components/MenuComponent";
import Contact from "../components/ContactComponent";

const Layout = () => {
    return (
      <Container fluid="true">
        <Row className="p-0 m-0 sticky-top">
          <Col className="d-none d-xl-block"></Col>
          <Col className="p-0 m-0 min-xl-screen-size">
            <Menu />
          </Col>
          <Col className="d-none d-xl-block"></Col>
        </Row>
        <Row className="p-0 m-0">
          <Col className="d-none d-xl-block"></Col>
          <Col className="p-0 m-0 min-xl-screen-size">

          <img className="img-fluid headerImg" alt="Logo Asociatia PsiNapsis Sibiu"></img>

          </Col>
          <Col className="d-none d-xl-block"></Col>
        </Row>
  
      <Outlet />
  
        <Row id="contact" className="p-0 m-0">
          <Col className="d-none d-xl-block"></Col>
          <Col className="p-0 m-0 min-xl-screen-size">
            <div className="contactsection">
              <Contact />
            </div>
          </Col>
          <Col className="d-none d-xl-block"></Col>
        </Row>

        <Row id="signature" className="p-0 m-0">
          <Col className="d-none d-xl-block"></Col>
          <Col className="whiteBg p-0 m-0 min-xl-screen-size">
            <div className="signature text-center pt-3">
              <p>{new Date().getFullYear()} &copy; <a href="https://www.linkedin.com/in/mihaela-sorina-cojocariu-ba240b285/">Ella Cojocariu</a></p>
            </div>
          </Col>
          <Col className="d-none d-xl-block"></Col>
        </Row>
  
      </Container>
    );
  }
  
  export default Layout;
  