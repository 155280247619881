import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col, Image } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Details from "../../components/DetailsComponent";

export default function AutismDayEvent() {
  const desc1 =
    "Cu ocazia Zilei Internationale de Constientizare a Autismului, Asociatia PsiNapsis va invita la lansarea unui ghid dedicat parintilor copiilor cu TSA, care contine informatii valoroase si accesibile despre diagnostic, terapie, miturile ce inconjoara aceasta tulburare cat si alte resurse utile. Brosura a fost realizata de catre colega noastra, Diana Calinescu, psiholog psihopedagogie speciala si logoped.";

  const desc2 =
    "Daca sunteti parinti ai unui copil diagnosticat cu autism sau ati observat astfel de semne la copilul dumneavoastra, va asteptam pentru a afla mai multe informatii despre autism si a va raspunde la intrebari, cat si pentru a cunoaste echipa noastra si spatiul in care ne desfasuram activitatea.";

  const desc3 =
    "In perioada urmatoare evenimentului, vom demara o campanie de evaluari gratuite pentru copiii cu suspiciune de autism care vor avea ca obiectiv identificarea varstei de dezvoltare, a abilitatilor si a trasaturilor specifice tulburarii de spectru autist.";

  return (
    <>
      <Helmet>
        <title>Ziua Internationala de Constientizare a Autismului</title>
        <meta
          name="description"
          content="Cu ocazia Zilei Internationale de Constientizare a Autismului, asociatia PsiNapsis va invita la lansarea unui ghid dedicat parinților copiilor cu TSA, care contine informatii valoroase si accesibile despre diagnostic, terapie, miturile ce inconjoara aceasta tulburare cat si alte resurse utile. "
        />

        <meta property="og:url" content="https://www.psinapsis.ro/events/autismdayevent" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="%PUBLIC_URL%/images/brosura2.png" />

        <link
          rel="canonical"
          href="https://www.psinapsis.ro/events/autismdayevent"
        />
      </Helmet>
      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">
          <Title title="Ziua Internationala de Constientizare a Autismului - Lansarea brosurii pentru parinti" />
          <Details normalText={desc1} />
          <Details normalText={desc2} />
          <Details normalText={desc3} />
          <div className="pWLink fs-6">
            <p>
              Pentru a afla mai multe detalii despre eveniment, va invitam sa
              cititi articolul publicat de catre Ora de Sibiu. Gasiti articolul
              integral{" "}
              <a
                href="https://www.oradesibiu.ro/2024/03/25/ajutor-pentru-parintii-din-sibiu-cu-copii-autisti-se-lanseaza-un-ghid-cu-informatii-utile-despre-terapie-si-diagnosticare/?fbclid=IwAR19Nf0yAGwm860_shYHJs4b1jsrGIk3fgOqnfyD9InCBvoMiaPy0bsjrNw"
                target="_blank"
                rel="noopener noreferrer"
              >
                aici.
              </a>
            </p>
          </div>
          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/calendar_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">2 aprilie 2024</span>
          </div>

          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/hour_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">17:00</span>
          </div>

          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/location_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">Asociația PsiNapsis</span>
          </div>
          <div className="text-center mt-3">
            <Image
              className="photoArticol mx-auto "
              src="/images/brosura2.png"
              alt="Interventia terapeutica in tulburarile de limbaj, Calinescu Diana, Asociatia PsiNapsis Sibiu"
            />
          </div>
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
