import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Details from "../../components/DetailsComponent";
import EventWImage from "../../components/EventWImageComponent";
import { Helmet } from "react-helmet-async";

export default function EvDislexieEvent() {
  const simptomeDislexie = [
    "Inversarea unor sunete, silabe in timpul cititului?",
    "Dificultati cu sunetele sau grupurile de sunete?",
    "Citit lent sau greoi, cu intreruperi?",
    "Omiterea sau modificarea inceputului sau sfarsitul unor cuvinte?",
    "Inlocuirea anumitor termeni cu unii familiari?",
    "Dificultati referitoare la orientarea in spatiu?",
  ];
  const textDislexie = "In 1 si 2 iunie organizam sedinte gratuite in vederea evaluarii dislexiei si a disortografiei de dezvoltare pentru elevii claselor a II-a si a III-a. Vom folosi DDE-2, care este o baterie de teste adaptata pentru Romania si destinata evaluarii nivelului de competenta dobandit atat in citire (lectura), cat si in scriere."

  return (
    <>
      <Helmet>
        <title>Evaluarea dislexiei si a disortografiei de dezvoltare</title>
        <meta
          name="description"
          content="Organizam sedințe gratuite in vederea evaluarii dislexiei si a disortografiei de dezvoltare. Vom folosi DDE-2, o baterie de teste destinata evaluarii nivelului de competenta dobandit atat in citire (lectura), cat si in scriere. Sedintele vor fi organizate in cadrul Asociatiei PsiNapsis din Sibiu."
        />
        <link rel="canonical" href="https://www.psinapsis.ro/events/evdislexieevent" />
      </Helmet>

      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">

          <Title title="Evaluarea dislexiei si a disortografiei de dezvoltare pentru elevii claselor a II-a si a III-a" />

          <Details normalText="Un 1 iunie altfel." />
          <Details normalText="Esti cadru didactic sau parinte? Cunosti/ ai un copil in clasa a II-a sau a III-a?" />
          <Details normalText="Ai observat:" />

          <EventWImage
            photoSource="/images/dislexieR.jpg"
            alt="Evaluarea dislexiei si disortografiei, Asociatia PsiNapsis, Sibiu"
            objectives={simptomeDislexie}
          />

          <Details
            normalText={textDislexie}
          />

          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/calendar_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">1-2 iunie 2023</span>
          </div>

          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/hour_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">12:00</span>
          </div>

          <div>
            <span className="ms-5 me-2">
              <Image src="/icons/location_icon.ico" width={20} height={20} />
            </span>
            <span className="fs-6 ms-1 me-5">Asociația PsiNapsis</span>
          </div>

          <Image
            className="photoEvent d-block d-md-none mx-auto "
            src="/images/dislexieR.jpg"
            alt="Evaluarea dislexiei si disortografiei, Asociatia PsiNapsis, Sibiu"
          />
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
