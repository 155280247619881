import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Details from "../../components/DetailsComponent";
import EventWImage from "../../components/EventWImageComponent";
import Objectives from "../../components/ObjectivesComponent";
import { Helmet } from "react-helmet-async";

export default function Anxiety() {

  const desc1 = "Conform Clinicii Mayo, experimentarea anxietatii ocazionale este o parte normala a vietii. Cu toate acestea, persoanele cu tulburari de anxietate simt in mod frecvent ingrijorare si frica in mod persistent, excesiv si cu o intensitate ridicata cu privire la situatiile de zi cu zi. Uneori, tulburarile de anxietate implica episoade repetate de sentimente bruste de anxietate intensa si frica care ating un apogeu in cateva minute, putand culmina cu atacul de panica."

  const desc2 = "Aceste sentimente de anxietate si panica interfereaza cu activitatile zilnice, sunt greu de controlat, sunt disproportionate cu pericolul real si pot dura mult timp. Persoanele suferind de anxietate pot evita locurile sau situatiile declansatoare pentru a preveni starile asociate. Simptomele pot incepe in copilarie sau in anii adolescentei si pot continua pana la varsta adulta."

  const simptome = [
    "Senzatie de nervozitate, agitatie sau tensiune;",
    "Sentiment de pericol iminent, panica sau pierdere;",
    "Dificultati de concentrare sau de gandire la altceva decat la ingrijorarea prezenta;",
    "O frecventa cardiaca crescuta;",
    "Respiratie rapida (hiperventilatie);",
    "Transpiratie;",
    "Tremurat;",
    "Senzatie de slabiciune sau oboseala;",
    "Probleme cu somnul;",
    "Probleme gastrointestinale;",
    "Dificultati in a controla grijile;",
    "Dorinta de a evita lucrurile care declanseaza anxietatea;",
  ];

  const factoriRisc = [
    "Trauma. Copiii care au suferit abuzuri sau traume sau au fost martori la evenimente traumatice au un risc mai mare de a dezvolta o tulburare de anxietate la un moment dat in viata. Adultii care experimenteaza un eveniment traumatic pot dezvolta si tulburari de anxietate.",
    "Stresul din cauza unei boli. A avea o stare de sanatate sau o boala grava poate provoca ingrijorari semnificative cu privire la probleme precum tratamentul si viitorul.",
    "Cresterea stresului. Un eveniment important sau o acumulare de situatii de viata stresante mai mici pot declansa anxietate excesiva - de exemplu, un deces in familie, stres la locul de munca sau ingrijorari continue cu privire la aspectele financiare.",
    "Personalitate. Persoanele cu anumite tipuri de personalitate sunt mai predispuse la tulburari de anxietate decat altele.",
    "Alte tulburari de sanatate mintala.",
    "Rude suferind de o tulburare de anxietate.",
    "Consumul de droguri sau alcool.",
  ];

  const preventie = [
    "Cauta ajutor devreme. Anxietatea, ca multe alte afectiuni de sanatate mintala, poate fi mai greu de tratat daca asteptati (poti apela la un specialist in sanatate mentala – psihiatru si/sau psiholog).",
    "Ramai activ. Participa la activitati care iti plac si care te fac sa te simti bine cu tine insuti.",
    "Bucura-te de interactiunea sociala si de relatiile semnificative, acestea va pot diminua grijile.",
    "Evita consumul de alcool sau droguri. Consumul de alcool si droguri poate provoca sau agrava anxietatea.",
  ];
  return (
    <>
      <Helmet>
        <title>Anxietatea</title>
        <meta
          name="description"
          content="Anxietatea - simptome, factori de risc si preventie. Articol redactat de Ilies Nicoleta, psiholog clinician si presedintele Asociatiei PsiNapsis din Sibiu."
        />
        <link rel="canonical" href="https://www.psinapsis.ro/articles/anxietyarticle" />
      </Helmet>
      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">
          <Title title="Anxietatea" />
          <Details normalText={desc1} />
          <Details normalText={desc2} />
          <Details
            highlightText="Semnele si simptomele "
            normalText="comune ce pot fi intalnite in anxietate includ:"
          />

          <EventWImage
            photoSource="/images/anxietate.jpg"
            alt="Terapie si consiliere adulti, Nicoleta Ilies, Asociatia PsiNapsis Sibiu"
            objectives={simptome}
          />
          <Image
            className="photoArticol d-block d-md-none mx-auto "
            src="/images/anxietate.jpg"
            alt="Terapie si consiliere adulti, Nicoleta Ilies, Asociatia PsiNapsis Sibiu"
          />

          <Details normalText="Exemple de tulburari de anxietate includ tulburarea de anxietate generalizata, tulburarea de anxietate sociala (fobie sociala), fobiile specifice si tulburarea de anxietate de separare." />

          <Details highlightText="Factori de risc" />

          <Objectives
            talign="objective-align-justify"
            objectives={factoriRisc}
          />

          <Details highlightText="Preventie" />

          <Details normalText="Nu exista nicio modalitate de a prezice cu siguranta ce va determina pe cineva sa dezvolte o tulburare de anxietate, dar puteti lua masuri pentru a reduce impactul simptomelor daca sunteti anxios:" />

          <Objectives talign="objective-align-justify" objectives={preventie} />
          <div className="text-center">
            <Image
              src="/images/anxietyR.png"
              alt="Terapie si consiliere adulti, Nicoleta Ilies, Asociatia PsiNapsis Sibiu"
              className="align-center photoAnxiety"
            />
          </div>

          <div className="pt-4">
            <Details normalText="Text redactat de: Nicoleta Ilies - Psiholog clinician " />
          </div>
          <Details normalText="27 februarie 2024" />
          <Details normalText="Resurse:" />
          <div className="mx-5 fs-6 linkArt">
            <a href="https://www.mayoclinic.org/diseases-conditions/anxiety/symptoms-causes/syc-20350961">
              https://www.mayoclinic.org/diseases-conditions/anxiety/symptoms-causes/syc-20350961
            </a>
          </div>
          <div className="mx-5 fs-6 linkArt">
            <a href="https://www.healthline.com/health/anxiety/effects-on-body">
              https://www.healthline.com/health/anxiety/effects-on-body
            </a>
          </div>
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
