import React from "react";
import { Container } from "react-bootstrap";

export default function Title(props) {
    return (
        <>
            <Container fluid className="title">
                <div className="separator mx-auto mt-5 min-md-screen-size"></div>
                <h1 className="titlesection text-center mx-4 fw-bold fs-4 mt-5 mb-5">{props.title}</h1>
            </Container>
        </>
    )
}