import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useState } from "react";
import Button from "react-bootstrap/Button";

export default function Employee(props) {
  const [isOpen, setIsOpen] = useState(false);
  let isMoreBtnNeeded = props.shortDescLength !== "0";
  return (
    <Container fluid>
      <Row className="teamElement rounded-pill mb-5 mx-4 d-flex flex-row align-items-center">
        <Col xs={12} sm={12} md="auto" lg="auto" xl="auto" xxl="auto">
          <Image
            className="photo mx-auto d-block d-lg-none"
            src={props.photo}
            alt={props.alt}
          ></Image>
        </Col>
        <Col className="d-block d-lg-none">
          <div className="name boldName text-center pb-2 pt-4 fs-6">{props.name}</div>
          <div className="justTxt p-1 pb-3 fs-6">
            {isOpen || !isMoreBtnNeeded
              ? props.description
              : props.description.substring(0, props.shortDescLength)}
          </div>
          {isMoreBtnNeeded && (
            <div className="text-center description fs-6 mb-3 mx-2 pb-3">
              <Button
                className="readmore"
                onClick={() => setIsOpen(!isOpen)}
                aria-controls="example-collapse-text"
                aria-expanded={isOpen}
              >
                Afla mai multe
              </Button>
            </div>
          )}
        </Col>

        <Col xs={12} sm={12} md="auto" lg="auto" xl="auto" xxl="auto">
          <Image
            id="photoForLarge"
            className="photo ms-n5 d-none d-lg-block"
            src={props.photo}
            alt={props.alt}
          ></Image>
        </Col>

        <Col className="mb-3 me-3 d-none d-lg-block">
          <div className="name boldName justTxt fs-6 mb-3">{props.name}</div>
          <div className="justTxt text-center fs-6 me-5">
            {props.description}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
