import React from "react";
import Title from "../components/TitleComponent";
import { Row, Col, Image } from "react-bootstrap";
import Details from "../components/DetailsComponent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet-async";

export default function VisitUsPage() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  const desc = "Asociatia noastra a luat fiinta natural si firesc in jurul unor deziderate comune, sprijinul comunitatii fiind unul dintre ele. Ne-am asumat sa oferim oportunitati de dezvoltare si evolutie cat mai calitative si sa cream relatii armonioase cu cei care ne trec pragul. Astfel, daca doriti sa va familiarizati cu activitatea si echipa noastra, cat si sa cunoasteti spatiul in care proiectele noastre iau nastere, va asteptam cu mare drag la sediul nostru." 

  return (
    <>
      <Helmet>
        <title>Viziteaza-ne</title>
        <meta
          name="description"
          content="Daca doriti sa va familiarizati cu activitatea si echipa Asociatiei PsiNapsis, si sa cunoasteti spatiul in care proiectele noastre iau nastere, va asteptam cu drag la sediul nostru."
        />
        <link rel="canonical" href="https://www.psinapsis.ro/visitus" />
      </Helmet>
      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">
          <div className="pb-5">
            <Title title="Viziteaza-ne" />
            <Details normalText={desc} />
            <Details normalText="Pentru a stabili o vizita:" />
            <Details normalText="Nicoleta Ilies - 0755622029" />
          </div>
          <Carousel
            fade
            swipeable={true}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carouselItem"
            responsive={responsive}
          >
            <Image
              className="photoCarousel"
              src="/images/photo1.jpg"
              alt="Ilies Nicoleta, Psiholog clinician Asociatia PsiNapsis Sibiu"
            />
            <Image
              className="photoCarousel"
              src="/images/photo2.jpg"
              alt="Nicoleta Ilies, Stroe Ioana, Psiholog clinician Asociatia PsiNapsis Sibiu"
            />
            <Image
              className="photoCarousel"
              src="/images/photo7.jpg"
              alt="Dancea Cristina, Stroe Ioana, Psiholog clinician Asociatia PsiNapsis Sibiu"
            />
            <Image
              className="photoCarousel"
              src="/images/photo6.jpg"
              a
              alt="Calinescu Diana, Ilies Nicoleta, Cojocariu Mihaela, Asociatia PsiNapsis Sibiu"
            />
            <Image
              className="photoCarousel"
              src="/images/EllaR.jpg"
              alt="Cojocariu Mihaela, Asociatia PsiNapsis Sibiu"
            />
            <Image
              className="photoCarousel"
              src="/images/sala_adulti_1.jpg"
              alt="Terapie adulti, Asociatia PsiNapsis Sibiu "
            />
          </Carousel>
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
