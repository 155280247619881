import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Details from "../../components/DetailsComponent";
import EventWImage from "../../components/EventWImageComponent";
import Objectives from "../../components/ObjectivesComponent";
import { Helmet } from "react-helmet-async";

export default function Dyslalia() {
  const difDislalia = [
    "Inlocuirea unor sunete cu altele;",
    "Omiterea sunetelor;",
    "Adaugarea acestora in anumite cuvinte;",
  ];

  const catDislalie = [
    "Dislalia simpla sau partiala, cand sunt afectate doar anumite sunete;",
    "Dislalia generala sau polimorfa, cand sunt alterate majoritatea sunetelor;",
  ];

  const intDislalie = [
    "Constientizare a respiratiei;",
    "Gimnastica fonoarticulatorie;",
    "Auz fonematic;",
    "Pronuntie a sunetului izolat, in silabe, in cuvinte;",
    "Consolidare si automatizare a sunetului in propozitii si conversatii;",
  ];
  return (
    <>
        <Helmet>
        <title>Dislalia</title>
        <meta
          name="description"
          content="Dislalia este cea mai frecventa tulburare de limbaj intalnita la copii. Articol redactat de Calinescu Diana, psiholog psihopedagogie speciala si logoped in cadrul Asociatiei PsiNapsis din Sibiu."
        />
        <link rel="canonical" href="https://www.psinapsis.ro/articles/dyslaliaarticle" />
      </Helmet>

      <Row className="p-0 m-0">
        <Col className="d-none d-xl-block"></Col>
        <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">
          <Title title="Dislalia" />

          <Details
          highlightText="Dislalia " 
          normalText="este cea mai frecventa tulburare de limbaj intalnita la copii, caracterizata prin dificultati in articularea sunetelor sau a silabelor, mai exact:" />

          <Objectives objectives={difDislalia} />

          <Details
            highlightText="Exista doua categorii de dislalie:"
          />
          
          <Objectives objectives={catDislalie} />

          <Details normalText="Abordarea timpurie a dislaliei asigura copiilor pregatirea necesara pentru activitatea lor ulterioara in scoala cat si in viata de zi cu zi, fiind foarte importanta pentru prevenirea insuccesului scolar." />

          <Details
          highlightText="Interventia terapeutica " 
          normalText="vizeaza exercitii de:" />
          
          <EventWImage
            photoSource="/images/dislalieSM.jpg"
            alt="Interventia terapeutica in tulburarile de limbaj, Calinescu Diana, Asociatia PsiNapsis Sibiu"
            objectives={intDislalie}
          />
          <Image
            className="photoArticol d-block d-md-none mx-auto "
            src="/images/dislalieSM.jpg"
            alt="Interventia terapeutica in tulburarile de limbaj, Calinescu Diana, Asociatia PsiNapsis Sibiu"
          />

          <div className="pt-4">
            <Details normalText="Text redactat de: Diana Calinescu - Psiholog psihopedagogie speciala, Logoped " />
          </div>
          <Details normalText="13 decembrie 2023" />
          
        </Col>
        <Col className="d-none d-xl-block"></Col>
      </Row>
    </>
  );
}
