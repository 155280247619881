import React from "react";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export default function DetailsWImage() {
  return (
    <>
      <Container>
        <Row>
          <Col className="textDoneaza"
            xs="12"
            sm="12"
            md="8"
            lg="7"
            xl="7"
            xxl="7"
          >
            <div>
            <p><b>Doneaza prin transfer bancar:</b></p>
            <p>Beneficiar: Asociatia PsiNapsis</p>
            <p>IBAN: RO54CECEB00030RON2726657 - LEI</p>
            <p>Banca: CEC Bank, Sucursala Sibiu</p>
            </div>
          </Col>
          <Col
            xs="12"
            sm="12"
            md="4"
            lg="5"
            xl="5"
            xxl="5" 
          >
            <Image className="photoDoneaza" src="/images/foto_terapie.jpg" alt="Donatii Asociatia PsiNapsis Sibiu" />
          </Col>
        </Row>
      </Container>
    </>
  );
}
