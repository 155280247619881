import React from "react";
import Title from "../../components/TitleComponent";
import { Row, Col } from "react-bootstrap";
import EventComponent from "../../components/EventComponent";
import { Helmet } from "react-helmet-async";

export default function TSAEvent() {

  const textTSA = "Pentru a marca sprijinul primit din colectarea formularelor de anul trecut si a sponsorizarilor din partea unor firme, carora le multumim, demaram, incepand cu luna februarie, un grup dedicat copiilor cu TSA, cu varste cuprinse intre 3 ani jumatate si 5 ani, care va avea ca obiective dezvoltarea abilitatilor de comunicare, socializare, relationare, precum si generalizarea achizitiilor de pe celellate arii de dezvoltare. Grupul va fi sustinut de un terapeut cu experienta in lucrul cu copiii cu TSA (inregistrat in registrul TSA de la COPSI), asistat de un co-terapeut."

  return (
    <>

    <Helmet>
        <title>Grup cu participare gratuita pentru copiii cu TSA</title>
        <meta
          name="description"
          content="Grupul are ca obiective dezvoltarea abilitatilor de comunicare si socializare, precum si generalizarea achizitiilor de pe celelalte arii de dezvoltare. Grupul se va desfasura in cadrul Asociatiei PsiNapsis din Sibiu si va fi sustinut de un terapeut inregistrat in registrul TSA de la COPSI. "
        />
        <link rel="canonical" href="https://www.psinapsis.ro/events/tsaevent" />
      </Helmet>

    <Row className="p-0 m-0">
      <Col className="d-none d-xl-block"></Col>
      <Col className="whiteBg p-0 m-0 pb-5 min-xl-screen-size">

        <Title title="Organizam grup cu participare gratuita pentru copiii cu TSA" />

        <EventComponent 
          normalText={textTSA}
          iconD="/icons/calendar_icon.ico"
          date="februarie-mai 2024"
          iconH="/icons/hour_icon.ico"
          hour="11:30"
          iconL="/icons/location_icon.ico"
          location="Asociatia PsiNapsis"
          photo="/images/sala_terapieR.jpg"
          alt="TSA Sibiu, Asociatia PsiNapsis"

        />
      </Col>
      <Col className="d-none d-xl-block"></Col>
    </Row>
    </>
  );
}
